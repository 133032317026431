import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-scroll';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(() => ({
    toolbarSecondary: {
      overflowX: 'hidden',
      color:'white',
      backgroundColor:"black",
      opacity:'45%',
    },
    toolbarLink: {
      color:"black",
      
    },
    overlay: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
      
  }));


export default function NavigationBarMb() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  return (
    <div className={classes.overlay}>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} 
        className={classes.toolbarSecondary}
        startIcon={<MenuIcon />}>
            Menu
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >                   
        <MenuItem>
            <Link onClick={handleClose}
                activeClass="active" to="home" 
                spy={true} smooth={true}   
                className={classes.toolbarLink} href="/#Home">Home
            </Link>
        </MenuItem>
        <MenuItem>
            <Link onClick={handleClose}
                to="about" spy={false} smooth={true}   
                className={classes.toolbarLink} href="/#About">About
            </Link>
        </MenuItem>
        <MenuItem>
            <Link onClick={handleClose}
                to="resume" spy={true} smooth={true}   
                href="/#Resume"
                className={classes.toolbarLink}>Resume
            </Link>
        </MenuItem>
        <MenuItem>
            <Link onClick={handleClose}
                to="projects" spy={true} smooth={true}  
                href="/#Projects" 
                className={classes.toolbarLink}>Projects
            </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}