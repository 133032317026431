import React from 'react';
import { SkillBars } from 'react-skills';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const skillsData=[
        
    {   
        name:'React',
        level: 60,
        color:'gray',
    },
    {   
        name:'JavaScript',
        level: 65,
        color:'black',
    },
    {
        name:'Rails',
        level: 70,
        color:'gray',
    },
    {   
        name:'CSS',
        level: 55,
        color:'black',
    },
]
const useStyles = makeStyles((theme) => ({
    root: {
        padding:0,
        justifyContent:"center",
        paddingBottom:50,
        backgroundColor: "white",
        color:"black",
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderBottomColor:"gray",
      },
      title:{
        textDecorationLine:'underline',
        textDecorationColor:'gray',
        textAlign:'Center',
        paddingBottom: 10,
      },
      chart:{
          justifyContent:'right',
      }
    }));


export default function SkillsMobile() {
    const classes = useStyles();
        return (
        <div className={classes.root}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <h2 className={classes.title}>Skills</h2>
                </Grid>
                <Grid item md={1}>
                </Grid>
                <Grid className={classes.chart} item xs={12}>
                    <SkillBars skills={skillsData} labelsWidth={80} barsHeight={40} />
                </Grid>
            </Grid>
        </div>      
        )
}