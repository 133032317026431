import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SkillsMobile from './SkillsMobile';
import EducationMb from './EducationMb';
import WorkMb from './WorkMb';

// RGBA(72,88,99,0.18)
const useStyles = makeStyles((theme) => ({
root: {
    paddingBottom:60,
    backgroundColor: "white",
    color:"black",
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderBottomColor:"gray",
  },
}));

export default function ResumeMb() {
    const classes = useStyles();

        return (
            <div id="resume"className={classes.root}>
                <WorkMb />
                <SkillsMobile />
                <EducationMb />
                
            </div>
        )
}