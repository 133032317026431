import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ShowsterLogo from '../assests/img/ShowsterLogo.png'
import Cw from '../assests/img/Cw.png';
import GameHub from '../assests/img/GameHub.png'
import Bar from '../assests/img/Bar.png'
import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflow: 'auto',

  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',

  },
  base:{
    padding:40,
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: "white",
    justifyContent: 'center',
    paddingBottom:80,

  },
  heading:{
    justifyContent:'center',

    textAlign:'center',
    color:"black",
    textDecorationLine:'underline',
    textDecorationColor:'gray',
    paddingBottom:30,
  },
  title: {
    color: theme.palette.action.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6) 70%, rgba(0,0,0,0) 100%)',
  },
  media: {
    height: "200px",
    width: "300px",
  },
  card:{
    justifyContent:"center",
    maxWidth:"300px",
  },
  
}));
export default function Projects() {
  const classes = useStyles();

  return (
    <div className={classes.base}>
        <Grid item xs={12} id="projects" className={classes.heading}>
            <h1>Projects</h1>
            <h3>Check out my code below</h3>
        </Grid>
      <GridList className={classes.gridList}>
        <GridListTile className={classes.card}>
            <a href="https://github.com/Cbreen61/showster-frontend"><img className={classes.media} src={ ShowsterLogo } alt="" /></a>
            <GridListTileBar
              title="Showster"
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
            />
          </GridListTile>
          <GridListTile className={classes.card}>
            <a href="https://github.com/Cbreen61/cw_weapon_builder_backend"><img className={classes.media} src={ Cw } alt="" /></a>
            <GridListTileBar
              title="Call of Duty Loadout Builder"
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
            />
          </GridListTile>
          <GridListTile className={classes.card}>
            <a href="https://github.com/Cbreen61/GameHub"><img className={classes.media} src={ GameHub } alt="" /></a>
            <GridListTileBar
              title="GameHub"
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
            />
          </GridListTile>
          <GridListTile className={classes.card}>
            <a href="https://github.com/Cbreen61/bar-cart"><img className={classes.media} src={ Bar } alt="" /></a>
            <GridListTileBar
              title="Bar-Cart"
              classes={{
                root: classes.titleBar,
                title: classes.title,
              }}
            />
          </GridListTile>
      </GridList>
    </div>
  );
}
