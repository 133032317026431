import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
    },
    justifyContent:"center",
    padding:40,
    textAlign:"center",
  },
  large: {
    width: theme.spacing(28),
    height: theme.spacing(32),
  },
  background: {
    backgroundColor: "rgb(6,6,6)",
    color: "white",
  },
  title:{
    textDecorationLine:'underline',
    textDecorationColor:'white',
    textDecorationThickness:'bold',
    paddingBottom:10,
  }
}));

export default function AboutMb() {
  const classes = useStyles();

  return (
    <div id="about"className={classes.background}>
      <div className={classes.root}>
        <Grid container spacing={10}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography className={classes.title} gutterBottom variant="h5">
                  About Me
                </Typography>
                <Typography variant="body1" gutterBottom>
                Software Engineer with a knack for technical problem solving, attention to writing clean code and commitment to hunting down bugs. Possesses strong skills in team-building and project management from 9 years experience in the hospitality industry. Seeking software development roles with a fast-paced company that values my persistence and quick ability to learn.
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  ID: 1030114
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    </div>
    </div>
  );
}