import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NewBanner from '../../assests/img/NewBanner.png';
import NavigationBarMb from './NavigationBarMb';




const useStyles = makeStyles(() => ({
  media: {
    height:"auto",
    width: "100%",
    position:"cover",
  },
  root:{
    backgroundColor:"black",
  },
}));

export default function HeaderMb() {
  const classes = useStyles();


  return (
    <div className={classes.root}>
      <div id="home">
        <NavigationBarMb className={classes.overlay}></NavigationBarMb>
      <img className={classes.media} src={ NewBanner } alt="Christopher Breen"></img>
      </div>
    </div>
  );
}