import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        padding:30,
        justifyContent:"center",
        paddingBottom:10,
        backgroundColor: "white",
        color:"black",
        flexGrow: 1,
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderBottomColor:"gray",
      },
      title:{
        textDecorationLine:'underline',
        textDecorationColor:'gray',
        textAlign:'center',
        paddingBottom: 10,
      },
      chart:{
          textAlign:'center',
          textDecorationThickness:'bold',
          paddingBottom: 10,

      },
      titles:{
        fontSize: 16,
      },
      desc:{
        flex: 1, 
        flexWrap: 'wrap',
        margin: 10,
        flexDirection:'row',
      },
    }));
export default function WorkMb() {
    const classes = useStyles();

        return (
            <div className={classes.root}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <h2 className={classes.title}>Experience</h2>
                </Grid>
                <Grid className={classes.chart} item sm={12}>
                <Typography variant="h5" component="h2">
                    Developer Seeking Opportunities
                </Typography>
                <Typography className={classes.titles} color="textSecondary" variant="subtitle1">
                     April 2021
                </Typography>
                <Typography  className={classes.desc}variant="body1" component="p">
                </Typography>
                </Grid>
                <Grid className={classes.chart} item sm={12}>
                <Typography variant="h5" component="h2">
                    Freelance Developer
                </Typography>
                <Typography className={classes.titles} color="textSecondary" variant="subtitle1">
                    CB Development <span>•</span> 2019 - Present
                </Typography>
                <Typography  className={classes.desc}variant="body1" component="p">
                    Created a scheduling tool to facilitate effective and efficient scheduling for restaurants.
                    <br />
                    Deployed Javascript code to allow for a more efficient collection and storage of sales data for management company.
                    <br />
                </Typography>
                </Grid>
                <Grid className={classes.chart} item sm={12}>
                <Typography variant="h5" component="h2">
                   Multi-Unit Manager
                </Typography>
                <Typography className={classes.titles} color="textSecondary" variant="subtitle1">
                    DIG Restaurant Group <span>•</span> July 2018 - 2020
                </Typography>
                <Typography  className={classes.desc}variant="body1" component="p">
                Developed a Fair Work Week analytics tool that tracked the financial state of restaurants against the law, and identified opportunities within the Fair Work Week framework that reduced potential fines by 2 million dollars.
                    <br />
                </Typography>
                </Grid>
            </Grid>
        </div>     
        )
}