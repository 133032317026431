
import './App.css';
import Footer from './components/Footer';
import Header from './components/desktop/Header';
import Projects from './components/Projects';
import About from './components/desktop/About';
import Resume from './components/desktop/Resume';
import React from 'react';
import ResumeMb from './components/mobile/ResumeMb';
import HeaderMb from './components/mobile/HeaderMb';
import AboutMb from './components/mobile/AboutMb';



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false
    };

    this.updatePredicate = this.updatePredicate.bind(this);
  }
  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 760 });
  }

  render() {
    const isDesktop = this.state.isDesktop;

    return (
      <div>
        {isDesktop ? (
          <div>
            <Header />
            <About />
            <Resume />
            <Projects />
            <Footer />
          </div>
        ) : (
          <div>
            <HeaderMb />
            <AboutMb />
            <ResumeMb />
            <Projects />
            <Footer />
          </div>
        )}
      </div>
    );
  }
}
export default App;

