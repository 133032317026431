import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';


const useStyles = makeStyles((theme) => ({
    root: { 
        justifyContent: 'center',
        backgroundColor:'black',
        color:"white",
        padding:20,
        textAlign:"center",
        fontSize:11,
    },
    git:{
        color:"purple",
    },
    icon:{
        color:"white",
    },
    link: {
        padding: theme.spacing(0,0),
    },
  }));
  


export default function Footer(){
    const classes = useStyles();



        return (
            <div id="contact" className={classes.root}>
            <BottomNavigation component="nav" variant="dense" className={classes.root} showLabels>
                <BottomNavigationAction className={classes.link}  href="https://www.linkedin.com/in/-christopher-breen/" color="primay" icon={<LinkedInIcon fontSize="large" color="primary" />} />
                <BottomNavigationAction className={classes.link}  href="https://github.com/Cbreen61" icon={<GitHubIcon fontSize="large" className={classes.git} />} />
                <BottomNavigationAction className={classes.link}  href="https://c-jbreen61.medium.com/" icon={<LibraryBooksIcon fontSize="large" className={classes.icon} />} />
                <BottomNavigationAction className={classes.link}  href="mailto:c.jbreen61@gmail.com" icon={<MailOutlineIcon fontSize="large" className={classes.icon} />} />
            </BottomNavigation>
            &copy; CopyRight 2022 CB Development.
            </div>
        //     <div>
            
        //         <Box
        //         display="flex"
        //         justifyContent='center'
        //         flexWrap="nowrap"
        //         p={1}
        //         m={1}
        //         bgcolor="background.paper"
        //         >

        //             <a href="https://www.linkedin.com/in/-christopher-breen/"><LinkedInIcon fontSize="large" color="primary" /></a>
        //             <Box justifyContent='center' p={1}><a href="https://github.com/Cbreen61"><GitHubIcon fontSize="large" style={{ color: deepPurple }} /></a></Box>
        //             <Box justifyContent='center' p={1}><a href="https://c-jbreen61.medium.com/"><LibraryBooksIcon fontSize="large" color="action" /></a></Box>
        //             <Box justifyContent='center' p={1}><a href="mailto:c.jbreen61@gmail.com"><MailOutlineIcon fontSize="large" color="action" /></a></Box>
        //         </Box>
        //  </div>
        )

}

