import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(() => ({
    root: {
        padding:20,
        justifyContent:"center",
        paddingBottom:10,
        backgroundColor: "white",
        color:"black",
        flexGrow: 1,
      },
      title:{
        textDecorationLine:'underline',
        textDecorationColor:'gray',
        textAlign:'center',
        paddingBottom: 10,
      },
      chart:{
          textAlign:'center',
          textDecorationThickness:'bold',

      },
      titles:{
        fontSize: 16,
      },
      desc:{
        flex: 1, 
        flexWrap: 'wrap',
        margin: 10,
        flexDirection:'row',
      },
    }));

export default function EducationMb() {
    const classes = useStyles();

        return (
            <div className={classes.root}>
            <Grid container >
                <Grid item xs={12}>
                    <h2 className={classes.title}>Education</h2>
                </Grid>
                <Grid className={classes.chart} item sm={12}>
                <Typography variant="h5" component="h2">
                    Flatiron School
                </Typography>
                <Typography className={classes.titles} color="textSecondary" variant="subtitle1">
                    Software Engineering <span>•</span> April 2021
                </Typography>
                <Typography  className={classes.desc}variant="body1" component="p">
                    Established fundamental CS principles such as Object-Oriented Programming, Data Structures and  Application Programming Interfaces.
                </Typography>
                </Grid>
            </Grid>
        </div>     
        )

}