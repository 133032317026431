import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import {Link} from 'react-scroll';



const useStyles = makeStyles((theme) => ({
    toolbarSecondary: {
      justifyContent: 'center',
      overflowX: 'hidden',
      color:'white',
      backgroundColor:"black",
      opacity:'45%',
    },
    toolbarLink: {
      padding: theme.spacing(3),
      flexShrink: 0,
      color:"white",
      
    },
    overlay: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      },
      
  }));


export default function NavigationBar()  {
    const classes = useStyles();
        return (
           
            <div className={classes.overlay}>
                <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
                    <Link
                     activeClass="active" to="home" 
                     spy={true} smooth={true}   
                     className={classes.toolbarLink} href="/#Home">Home</Link>
                    <Link 
                        to="about" spy={false} smooth={true}   
                        className={classes.toolbarLink} href="/#About">About</Link>
                    <Link 
                        to="resume" spy={true} smooth={true}   
                        href="/#Resume"
                        className={classes.toolbarLink}>
                            Resume
                    </Link>
                    <Link 
                        to="projects" spy={true} smooth={true}  
                        href="/#Projects" 
                        className={classes.toolbarLink}>
                            Projects
                    </Link>
                </Toolbar>
            </div>
        );
}

