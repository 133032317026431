import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skills from './Skills';
import Education from './Education';
import Work from './Work';

// RGBA(72,88,99,0.18)
const useStyles = makeStyles((theme) => ({
root: {
    padding:50,

    paddingBottom:100,
    backgroundColor: "white",
    color:"black",
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderBottomColor:"gray",
  },
  section:{
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderBottomColor:"gray",

  },
}));

export default function Resume() {
    const classes = useStyles();

        return (
            <div id="resume"className={classes.root}>
                <Work />
                <Skills />
                <Education />
                
            </div>
        )
}